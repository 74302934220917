import * as React from 'react';

function Section2() {
  return (
    <section
      className="
      flex
      content-center
      justify-center
      xl:pt-[45px]
      lg:pt-[90px]
      lg:pb-[88px]
      mobile:pt-[0]
      mobile:px-[15px]
    "
    />
  );
}

export default Section2;
