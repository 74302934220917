import * as React from 'react';
import Metric from './components/metric/metric';

import img0 from '../../../images/index-metric-1.png';
import img1 from '../../../images/index-metric-2.png';
import img2 from '../../../images/index-metric-3.png';

import { section, ctaBtn } from './section-1.module.css';

function Section1({ pageData }) {
  const { metrics, metricExplanations, metricHeading, metricHeadingMobile } =
    pageData;
  const explanationReflections = [img0, img1, img2];
  const titleClassNames = `
    font-rubik
    font-bold
    xl:text-[50px]
    text-white
    leading-[65px]
  `;
  return (
    <section
      className={`
      ${section}
      xl:pb-[75px]
      lg:pb-[50px]
      lg:px-[15px]
      mobile:p-0
    `}
    >
      <div
        className={`
        xl:w-[1440px]
        lg:w-[980px]
        mobile:w-full
        mx-auto
      `}
      >
        <h2
          className={`
          ${titleClassNames}
          xl:py-[75px]
          xl:pl-[98px]
          mobile:hidden
          lg:text-40
          lg:pl-[12px]
          lg:py-[57px]
        `}
        >
          {metricHeading}
        </h2>
        <h2
          className={`
          ${titleClassNames}
          py-[50px]
          hidden
          mobile:block
          mobile:text-center
          mobile:text-32
          mobile:leading-[40px]
        `}
        >
          {metricHeadingMobile}
        </h2>
        <div
          className="
          flex
          overflow-hidden
          justify-center
          content-center
          h-[257px]
          mobile:flex-col
          mobile:w-full
          mobile:mx-auto
          mobile:mb-[100px]
          mobile:h-full
        "
        >
          {metrics.map((metric, i) => (
            <Metric
              key={metric}
              idx={i}
              metric={metric}
              explanation={metricExplanations[i]}
              img={explanationReflections[i]}
            />
          ))}
        </div>
      </div>
      <div
        className={`
        ${ctaBtn}
        lg:hidden
        mobile:block
        bg-white
        text-center
        mobile:mx-0
      `}
      />
    </section>
  );
}

export default Section1;
